import React from 'react';
import HomePage from './HomePage';
import Payment from './Payment';
import hs3 from '../apis/hs3';
import paystack from '../apis/paystack';
import queryString from 'query-string';

class App extends React.Component {
	state = { isHomePage: true, totalEnroll: null, enrolleeInfo: null, errorMessage: null, paymentStatus: null };
	
	componentDidMount() {
        this.getEnrollmentTotal();
		if (window.location.search && window.location.search.length > 15) {
			this.setState({ isHomePage: false });
			
			const parsed = queryString.parse(window.location.search);
			this.verifyPayment(parsed.reference);
			
			// console.log("query: ", window.location.search);
			// console.log("parsed: ", parsed.reference);
			//alert("Here we go!");
		}
    }
	
	getEnrollmentTotal = async () => {
		const response = await hs3.get('/all/enrollments');
		if (response.data) {
			const num = parseInt(response.data.data);
			this.setState({ totalEnroll: num.toLocaleString() });
		}
		
		//const response2 = await hs3.get('/all/unprinted', { params: {limit: 1, skip: 0 } });	
		//console.log(response.data, response2);
	}
	
	onEnrollmentIDInputBlur = async (enrolmentid) => {
		const response = await hs3.get('/enrollment/verify', { params: { EnrID: enrolmentid } });
		if (response.data.status) {
			this.setState({ enrolleeInfo: response.data.data }); 
		} else {
			this.setState({ errorMessage: response.data.message });
		}
		
		//Object.keys(response.data).map(key => response.data[key]).forEach(item => console.log(item));
		//Object.keys(data["bpi"]).map(key => data["bpi"][key]).forEach(item => console.log(item))
	}
	
	onProceedToPayClick = async (enrol_id, names, current_plan, upgrade_plan, frequency, topay) => {
		const response = await paystack.post('/api/paystack/initialize', { name: names, email: "plateauhealth@instantdeposit.africa", phone: "07030424007", amount: topay, callback_url: "https://plateauhealth.online/", payment_type: "PLASCHEMA" } );
		if (response.data.status) {
			// save data to PLASCHEMA database
			//savePaymentRecord(enrol_id, names, current_plan, upgrade_plan, frequency, amount, response.data.data.reference);
			
			// redirect to Paystack here...
			window.open(response.data.data.authorization_url, '_self');
		}
	}
	
	verifyPayment = async (reference) => {
		//console.log("param to verify: ", reference);
		const response = await paystack.post('/api/paystack/verify', { reference: reference } );
		if (response.data.status) {
			this.setState({ paymentStatus: response.data });
			
			// update earlier created record with successful payment status.
			//updatePaymentRecord(reference);
		} else {
			this.setState({ errorMessage: response.data.message });
		}
	}
	
	savePaymentRecord = async (enrol_id, names, current_plan, upgrade_plan, frequency, amount, reference) => {
		//const response = await hs3.post('/subscription/pay', { params: { EnrID: enrol_id, Names: names, Plan: current_plan, Frequency: frequency, Amount: amount, transaction_id: reference } });
	}
	
	updatePaymentRecord = async (reference) => {
		//const response = await hs3.patch('/subscription/pay', { params: {transaction_id: reference } });
	}
	
	onPaymentPageClick = () => {
        this.setState({ isHomePage: false });
    }
	
	onHomeClick = () => {
        this.setState({ isHomePage: true });
    }
	
	onSubmitRequestClick = (enrolmentid, reason) => {
		//call API to store appointment details and send SMS to enrollee
	}

    loadBody = () => {
		if(this.state.isHomePage) {
            return (
                <div>
                    <HomePage onPaymentClick={this.onPaymentPageClick} onSubmitRequestClick={this.onSubmitRequestClick} enrollCount={this.state.totalEnroll} />
                </div>
            )
        }

        return (
            <div>
                <Payment onHomeClick={this.onHomeClick} onEnrollmentIDInputBlur={this.onEnrollmentIDInputBlur} enrolleeInfo={this.state.enrolleeInfo} onProceedClick={this.onProceedToPayClick} errorMessage={this.state.errorMessage} paymentStatus={this.state.paymentStatus} />
            </div>
        )
    }

    render() {
				
        return (
            <div>
                {this.loadBody()};
            </div> 
        );
    }
}

export default App;