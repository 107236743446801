import React from 'react';

class Payment extends React.Component {
	state = { enrollmentmentid: '', reason: '', paymentinfo: null, upgradeplan: null, paymentfrequency: null, amount: 0.0, displayAmount: null };
	
	onInputChange = (event) => {
		this.setState({amount: 0.0});
		
        const target = event.target;
        const value = target.type === 'radio' ? target.checked : target.value;
        
        this.setState({ [target.name]: value });
    }
	
	renderAmount = () => {
		if (this.state.upgradeplan && this.state.paymentfrequency && this.state.upgradeplan !== "" && this.state.paymentfrequency !== "") {
			const amt = parseFloat(this.state.upgradeplan)/parseFloat(this.state.paymentfrequency);
			
			this.setState({ amount: amt.toFixed(2) });
			this.setState({ displayAmount: amt.toLocaleString(undefined, {maximumFractionDigits:2}) });
			//console.log(this.state.amount);
		}
	}

	onInputBlur = () => {
		if (this.state.enrollmentmentid) {
			this.props.onEnrollmentIDInputBlur(this.state.enrollmentmentid);
		}
	}
	
	onHomeClick = () => {
        this.props.onHomeClick();
    }
	
	onProceedClick = (event) => {
		event.preventDefault();
        // call API to generate Paystack reference and proceed to checkout
		this.props.onProceedClick(this.props.enrolleeInfo.ENRID, this.props.enrolleeInfo.Name, this.props.enrolleeInfo.plan, this.state.upgradeplan, this.state.paymentfrequency, this.state.amount);
		//console.log("props.enrolleeInfo: ", this.props.enrolleeInfo)
    }
	
	loadBody = () => {
		if (this.props.enrolleeInfo) {
			return (
				<div class="bg-light p-3 my-3">
					<table class="table table-striped">
						<tr>
							<td><b>Name:</b></td>
							<td>{this.props.enrolleeInfo.Name}</td>
						</tr>
						<tr>
							<td><b>Enrollment ID:</b></td>
							<td>{this.props.enrolleeInfo.ENRID}</td>
						</tr>
						<tr>
							<td><b>Current Plan:</b></td>
							<td>{this.props.enrolleeInfo.Plan}</td>
						</tr>
						<tr>
							<td><b>Upgrade Plan:</b></td>
							<td><select name="upgradeplan" onBlur={this.renderAmount} onChange={this.onInputChange} class="form-control">
								<option value="">Select Plan</option>
								<option value="12000">Informal</option>
								<option value="16000">Private</option>
							</select></td>
						</tr>
						<tr>
							<td><b>Payment Frequency:</b></td>
							<td><select name="paymentfrequency" onBlur={this.renderAmount} onChange={this.onInputChange} class="form-control">
								<option value="">Select Frequency</option>
								<option value="1">Every year</option>
								<option value="2">Every 6 months</option>
								<option value="4">Every 3 months</option>
								<option value="12">Every month</option>
							</select></td>
						</tr>
						<tr>
							<td><b>Amount:</b></td>
							<td>=N={this.state.displayAmount}</td>
						</tr>
					</table>
				</div>
			)
		} else if (this.props.paymentStatus) {
			return <h4 class="bg-light p-3 my-3">Subscription payment for Plateau Health was successful!<p></p> <p>You may initiate another subscription payment by entering Enrollment ID above.</p></h4>
		} else if (this.props.errorMessage) {
			return <h4 class="bg-light p-3 my-3">{this.props.errorMessage}</h4>
		} else {
			return <h4 class="bg-light p-3 my-3">Please provide valid Enrollment ID to proceed...</h4>
		}
    }
		
		

    render () {
		return (
			<div>
				<div class="top">
					<div class="container-fluid">
						<div class="row px-3">
						<div class="col-md-10">
							<div class="logo">
								<img src="assets/images/plaschema-logo.png" />
							</div>
						</div>
						<div class="col-md-2">
							<div class="mt-3 pull-right">
								<button onClick={this.onHomeClick} class="btn btn-success btn-block">BACK TO HOME</button>
							</div>
						</div>
						</div>
					</div>
				</div>

				<div class="header">
					<div class="container-fluid">
						<div class="top-banner2">
								<h2 class="mt-3 text-center">Plateau Health Subscription Payment</h2>
								<div class="pay-side mx-auto">
									<form class="py-2">
										<div class="form-group">
											<input type="text" name="enrollmentmentid" class="form-control" placeholder="Enter Enrollment ID" value={this.state.enrollmentmentid} onChange={this.onInputChange} onBlur={this.onInputBlur} />
											<div>
												{this.loadBody()};
											</div>
											<p>
											{this.state.amount > 0? <button onClick={this.onProceedClick} class="btn btn-lg btn-success btn-block">Proceed</button> : <div></div>}
											</p>
										</div>
									</form>
								</div>
						</div>
							
					</div>
				</div>
				<div class="clearfix mt-5"></div>

				<div class="footer">
					Copyright © Plateau State Contributory Health Care Management Agency - Developed by <a href="https://instantdeposit.africa/">Instant Deposit Ltd</a> 2020
				</div>
					
			</div>
		);
    }
};

export default Payment;