import React from 'react';

class HomePage extends React.Component {
	state = {enrollmentmentid: '', reason: ''};
	
	onInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'radio' ? target.checked : target.value;
        
        this.setState({ [target.name]: value });
    }
	
	onSubmitClick = () => {
        this.props.onSubmitRequestClick(this.state.enrollmentmentid, this.state.reason);
    }
	
	onPaymentClick = () => {
        this.props.onPaymentClick();
    }

    render () {
		return (
			<div>
				<div class="top">
					<div class="container-fluid">
						<div class="row px-3">
							<div class="col-md-10">
								<div class="logo">
									<img src="assets/images/plaschema-logo.png" />
								</div>
							</div>
							<div class="col-md-2">
								<div class="mt-3 pull-right">
									<a href="https://dashboard.plateauhealth.online/" target="_blank" class="btn btn-success btn-block btn-lg">LOGIN</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="header">
					<div class="container-fluid">
						<div class="top-banner">
								<h2 class="mt-3">Plateau State Universal Health Care by PLASCHEMA</h2>
								<h4 class="mt-4">Welcome to Plateau Health portal. Our mandate is to ensure access to affordable, equitable, timely and quality healthcare, with financial security for all residents of Plateau State, irrespective of their socioeconomic status.</h4>
								<div class="side-banner">
								</div>
						</div>
							
					</div>
				</div>
				<div class="clearfix"></div>

				<div class="sections">
					<div class="container-fluid">
						<div class="row">
							<div class="col-md-3">
								<div class="section">
								  <div class="icon-home">
									<i class="fa fa-users"></i>
								  </div>
									<h3>{!this.props.enrollCount?<div>25k</div>:<div>{this.props.enrollCount}</div>}</h3>
									<p>Total Enrollment</p>
									<p class="mt-4"><a href="#enroll" data-toggle="modal" class="btn btn-success btn-block btn-lg">Enroll Today</a></p>
								</div>
							</div>
							<div class="col-md-3">
								<div class="section">
								  <div class="icon-home">
									<i class="fa fa-clinic-medical"></i>
								  </div>
									<h3>28,100</h3>
									<p>BHCPF Beneficiaries</p>
									<p class="mt-4"><a href="https://bhcpf.plateauhealth.online" target="_blank" class="btn btn-success btn-block btn-lg">View Here </a></p>
								</div>
							</div>
							<div class="col-md-3">
								<div class="section">
								  <div class="icon-home">
									<i class="fa fa-notes-medical"></i>
								  </div>
									<h3>31k</h3>
									<p>Active Subscriptions</p>
									<p class="mt-4"><button onClick={this.onPaymentClick} class="btn btn-success btn-block btn-lg">Pay Subscription</button></p>
								</div>
							</div>
							<div class="col-md-3">
								<div class="section">
								  <div class="icon-home">
									<i class="fa fa-user-md"></i>
								  </div>
									<h3>3,650+</h3>
									<p>Health Care Professionals</p>
									<p class="mt-4"><a href="#book" data-toggle="modal" class="btn btn-success btn-block btn-lg">Book Appointment</a></p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="footer">
					Copyright © Plateau State Contributory Health Care Management Agency - Developed by <a href="https://instantdeposit.africa/">Instant Deposit Ltd</a> 2020
				</div>

				<div class="modal fade" id="enroll" tabindex="-1" aria-labelledby="enroll" aria-hidden="true">
				<div class="modal-dialog">
				  <div class="modal-content">
					<div class="modal-header">
					  <h5 class="modal-title" id="enroll">Enrollment Information</h5>
					  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					  </button>
					</div>
					<div class="modal-body">
					  <p class="lead text-center">Enrollment is ongoing at every Ward in Plateau State. You may call us on 0700-752-8328 (0700PLATEAU) for enquiries or complaints. Thank you</p>
					</div>
					<div class="modal-footer">
					  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
					</div>
				  </div>
				</div>
			  </div>

			<div class="modal fade" id="book" tabindex="-1" aria-labelledby="book" aria-hidden="true">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
						  <h5 class="modal-title" id="book">Book an Appointment</h5>
						  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						  </button>
						</div>
						<div class="modal-body">
						  <form>
								<div class="form-group">
									  <label>Enrollment ID</label>
									  <input type="text" name="enrollmentmentid" class="form-control" placeholder="Type your enrollment ID here" value={this.state.username} onChange={this.onInputChange} />
								</div>
								<div class="form-group">
									<label>Reason</label>
									<textarea name="reason" class="form-control" placeholder="Why do you want to see a Doctor?" value={this.state.username} onChange={this.onInputChange}></textarea>
								</div>
								<div class="form-group">
									<button onClick={this.onSubmitClick} type="button" class="btn btn-primary btn-lg" data-dismiss="modal">Submit Request</button>
								</div>
						  </form>
						</div>
					</div>
				</div>
			</div>
			
		  </div>
		);
    }
};

export default HomePage;